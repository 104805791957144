<template>
  <v-row>
    <v-col class="col-sm-2 d-none d-sm-flex"></v-col>
    <v-col align="center" class="col-12 col-sm-8">
      <!-- Header -->
      <v-row justify="center">
        <h2 class="pa-5">{{ testerData.FName + " " + testerData.LName }}</h2>
        <div class="error--text mt-3">{{ error }}</div>
        <v-icon v-if="loading">mdi mdi-loading mdi-spin</v-icon>
      </v-row>
      <!-- Tabs -->
      <v-tabs v-model="tabs" fixed-tabs>
        <v-tab>Account Details</v-tab>
        <v-tab>Experience</v-tab>
        <v-tab>Console Details</v-tab>
        <v-tab>Desktop Details</v-tab>
        <v-tab>Mobile Details</v-tab>
        <v-tab>Payment History</v-tab>
      </v-tabs>
      <v-tabs-items class="mt-5" v-model="tabs">
          <!-- Account Details -->
          <v-tab-item>
            <!-- Data -->
            <v-row>
              <v-col cols="2"></v-col>
              <v-col cols="8">
                <v-card class="pa-5">
                  <v-row>
                    <v-col cols="6" class="pa-3 pr-3">
                      <v-text-field label="Discord ID" v-model="testerData.DiscordID"></v-text-field>
                      <v-text-field label="First Name" v-model="testerData.FName"></v-text-field>
                      <v-text-field label="Last Name" v-model="testerData.LName"></v-text-field>
                      <v-select label="Country"></v-select>
                      <v-text-field label="City"></v-text-field>
                      <v-select label="State/Provice"></v-select>
                      <v-select label="Age Range"></v-select>
                      <v-select label="Gender"></v-select>
                      <v-text-field label="Contact Email" v-model="testerData.Email"></v-text-field>
                      <v-text-field label="PayPal Email"></v-text-field>
                    </v-col>
                    <v-col cols="6" class="pa-3 pl-3">
                      <v-select label="Internet Connection"></v-select>
                      <v-text-field label="ISP"></v-text-field>
                      <v-select label="Download Speed"></v-select>
                      <v-select label="Upload Speed"></v-select>
                      <v-text-field label="UPlay Username"></v-text-field>
                      <v-text-field label="UPlay Email"></v-text-field>
                      <v-select label="Gaming Hours/Week"></v-select>
                      <v-text-field label="Game Genres"></v-text-field>
                    </v-col>
                  </v-row>
                </v-card>
              </v-col>
              <v-col cols="2"></v-col>
            </v-row>
            <!-- Controls -->
            <v-row class="pa-5" align="center" justify="center">
              <v-btn class="mr-3 white--text" color="#6FBDDB" :loading="loading">Update Account</v-btn>
              <v-btn class="mr-3 white--text" color="#6FBDDB" :loading="loading">Change Password</v-btn>
              <v-btn class="mr-3 white--text" color="#6FBDDB" :loading="loading">Delete Account</v-btn>
            </v-row>
          </v-tab-item>
          <!-- Experience -->
          <v-tab-item>
            <!-- Data -->
            <v-row>
              <v-col cols="2"></v-col>
              <v-col cols="8">
                <v-card class="pa-5">
                  <v-row>
                    <v-col cols="6" class="pa-3 pr-3">
                      <v-select label="Industry Experience"></v-select>
                      <v-text-field label="Job Title"></v-text-field>
                      <v-text-field label="Nature of Experience"></v-text-field>
                      <v-select label="Years of Experience"></v-select>
                      <v-select label="Related Education/Training"></v-select>
                      <v-text-field label="Nature of Education/Training"></v-text-field>
                    </v-col>
                    <v-col cols="6" class="pa-3 pl-3">
                      <v-select label="Looking for Work"></v-select>
                      <v-text-field label="Nature of Work"></v-text-field>
                      <v-text-field label="Type of Work"></v-text-field>
                      <v-text-field label="Business Software Experience"></v-text-field>
                      <v-text-field label="Programming Languages"></v-text-field>
                    </v-col>
                  </v-row>
                </v-card>
              </v-col>
              <v-col cols="2"></v-col>
            </v-row>
            <!-- Controls -->
            <v-row class="pa-5" align="center" justify="center">
              <v-btn class="mr-3 white--text" color="#6FBDDB" :loading="loading">Update Experience</v-btn>
              <v-btn class="mr-3 white--text" color="#6FBDDB" :loading="loading">Change Password</v-btn>
              <v-btn class="mr-3 white--text" color="#6FBDDB" :loading="loading">Delete Account</v-btn>
            </v-row>
          </v-tab-item>
          <!-- Console Details -->
          <v-tab-item>
            <!-- Data -->
            <v-row>
              <v-col cols="2"></v-col>
              <v-col cols="8">
                <v-card class="pa-5">
                  <v-row>
                    <v-col cols="6" class="pa-3 pr-3">
                      <v-select label="PS4 Version"></v-select>
                      <v-text-field label="PSN ID"></v-text-field>
                      <v-select label="Xbox One Version"></v-select>
                      <v-text-field label="Xbox Live Name"></v-text-field>
                      <v-select label="Nintendo Switch Version"></v-select>
                      <v-text-field label="Nintendo Store ID"></v-text-field>
                    </v-col>
                    <v-col cols="6" class="pa-3 pl-3">
                      <v-select label="PS4 Headset"></v-select>
                      <v-select label="PS4 Connection"></v-select>
                      <v-select label="Xbox One Headset"></v-select>
                      <v-select label="Xbox One Connection"></v-select>
                      <v-btn class="mr-3 white--text" color="#6FBDDB" :loading="loading">+ Add Hardware</v-btn>
                    </v-col>
                  </v-row>
                </v-card>
              </v-col>
              <v-col cols="2"></v-col>
            </v-row>
            <!-- Controls -->
            <v-row class="pa-5" align="center" justify="center">
              <v-btn class="mr-3 white--text" color="#6FBDDB" :loading="loading">Update Consoles</v-btn>
              <v-btn class="mr-3 white--text" color="#6FBDDB" :loading="loading">Change Password</v-btn>
              <v-btn class="mr-3 white--text" color="#6FBDDB" :loading="loading">Delete Account</v-btn>
            </v-row>
          </v-tab-item>
          <!-- Desktop Details -->
          <v-tab-item>
            <!-- Data -->
            <v-row>
              <v-col cols="2"></v-col>
              <v-col cols="8">
                <v-card class="pa-5">
                  <v-row>
                    <v-col cols="6" class="pa-3 pr-3">
                      <v-select label="CPU Brand"></v-select>
                      <v-select label="GPU Brand"></v-select>
                      <v-select label="RAM"></v-select>
                      <v-select label="PC Headset"></v-select>
                      <v-select label="PC Controller"></v-select>
                    </v-col>
                    <v-col cols="6" class="pa-3 pl-3">
                      <v-select label="CPU Speed"></v-select>
                      <v-select label="GPU Memory"></v-select>
                      <v-select label="Connection Type"></v-select>
                      <v-select label="Xbox One Connection"></v-select>
                      <v-text-field label="Steam Username"></v-text-field>
                    </v-col>
                  </v-row>
                </v-card>
              </v-col>
              <v-col cols="2"></v-col>
            </v-row>
            <!-- Controls -->
            <v-row class="pa-5" align="center" justify="center">
              <v-btn class="mr-3 white--text" color="#6FBDDB" :loading="loading">Update Desktop</v-btn>
              <v-btn class="mr-3 white--text" color="#6FBDDB" :loading="loading">Change Password</v-btn>
              <v-btn class="mr-3 white--text" color="#6FBDDB" :loading="loading">Delete Account</v-btn>
            </v-row>
          </v-tab-item>
          <!-- Mobile Details -->
          <v-tab-item>
            <!-- Data -->
            <v-row>
              <v-col cols="2"></v-col>
              <v-col cols="8">
                <v-card class="pa-5">
                  <v-row >
                    <v-col cols="6" class="pa-3 pr-3">
                      <v-select label="iPhone Version"></v-select>
                      <v-select label="iPad Version"></v-select>
                      <v-select label="Android Phone Version"></v-select>
                      <v-select label="Android Tablet Version"></v-select>
                    </v-col>
                    <v-col cols="6" class="pa-3 pl-3">
                    </v-col>
                  </v-row>
                </v-card>
              </v-col>
              <v-col cols="2"></v-col>
            </v-row>
            <!-- Controls -->
            <v-row class="pa-5" align="center" justify="center">
              <v-btn class="mr-3 white--text" color="#6FBDDB" :loading="loading">Update Mobile</v-btn>
              <v-btn class="mr-3 white--text" color="#6FBDDB" :loading="loading">Change Password</v-btn>
              <v-btn class="mr-3 white--text" color="#6FBDDB" :loading="loading">Delete Account</v-btn>
            </v-row>
          </v-tab-item>
          <!-- Payment History -->
          <v-tab-item>
            <!-- Data -->
            <v-row>
              <v-col cols="2"></v-col>
              <v-col cols="8">
                <v-card class="pa-5">
                </v-card>
              </v-col>
              <v-col cols="2"></v-col>
            </v-row>
            <!-- Controls -->
            <v-row class="pa-5" align="center" justify="center">
              <v-btn class="mr-3 white--text" color="#6FBDDB" :loading="loading">Contact DAQA</v-btn>
              <v-btn class="mr-3 white--text" color="#6FBDDB" :loading="loading">Change Password</v-btn>
              <v-btn class="mr-3 white--text" color="#6FBDDB" :loading="loading">Delete Account</v-btn>
            </v-row>
          </v-tab-item>
      </v-tabs-items>
    </v-col>
    <v-col class="col-sm-2 d-none d-sm-flex"></v-col>
  </v-row>
</template>

<script>
  import { mapGetters } from 'vuex'

  export default {
    name: 'TesterProfile',

    data () {
      return {
        error: '',
        loading: false,
        tabs: '',
        testerData: {}
      }
    },

    computed: {
      ...mapGetters([
        'userData'
      ])
    },

    created () {
      this.getTesterData()
    },

    methods: {
      getTesterData () {
        const id = this.$route.query.testerID
        this.$store.dispatch("getTester", { id })
        .then((resp) => {
          this.loading = false
          this.testerData = resp
        })
        .catch((err) => {
          this.loading = false
          this.error = err
        })
      }
    }
  }
</script>

<style scoped>

</style>